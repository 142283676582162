import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Accordion from './Accordion/Accordion';

const Section = styled.section`
  max-width: 1050px;
  margin: 0 auto;
  padding: 120px 30px;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopMed}) {
    padding: 90px 30px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 43px 5px 122px;
  }

  h2 {
    font-size: 42px;
    font-weight: 100;
    text-align: center;
    color: #4f289a;
    margin-bottom: 100px;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopMed}) {
      margin-bottom: 60px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      font-size: 35px;
      margin-bottom: 38px;
    }
  }

  strong {
    font-weight: 600;
  }
`;

const Accordions = ({ children, data }) => {
  const accordionsElements = data.map(faqItem => {
    const { index, question, answer } = faqItem;
    return <Accordion key={index} question={question} answer={answer} />;
  });

  return (
    <Section id="dowiedz-sie-wiecej">
      {children}
      <ul>{accordionsElements}</ul>
    </Section>
  );
};

Accordions.propTypes = {
  children: PropTypes.object,
  data: PropTypes.array,
};

export default Accordions;
