import React, {Component} from 'react';

import Layout from '../components/layout';
import PageHero from '../components/PageHero/PageHero';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Accordions from '../components/Accordions/Accordions';
import ParallaxBlock from '../components/ParallaxBlock/ParallaxBlock';
import BtnPlay from '../components/BtnPlay/BtnPlay';
import Footer from '../components/Footer/Footer';

import ParallaxImg from '../assets/images/ParallaxImg2.png';

import faq from '../data/faq';
import SEO from "../components/seo";
import styled from "styled-components";
import {BtnMore} from "../components/Hero/Hero";

const StyledBtnMore = styled(BtnMore)`
  display: inline-block;
  margin: 60px auto 0;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 50px auto 0;
  }
`;

class howItWorks extends Component {
  constructor () {
    super();
  }

  render() {
    return (
      <Layout>
        <SEO title="Jak to działa" />
        <PageHero pageName="jak-to-dziala">
          <h1>
            Jak to <strong>działa?</strong>
          </h1>
        </PageHero>
        <HowItWorks heading={false}>
          <StyledBtnMore to="/co-otrzymasz">Poznaj korzyści</StyledBtnMore>
        </HowItWorks>
        <ParallaxBlock background={ParallaxImg} padding="md">
          <ParallaxBlock.HeadingLg>
            Obejrzyj <strong>film</strong>
          </ParallaxBlock.HeadingLg>
          <BtnPlay color="white" size="big"/>
        </ParallaxBlock>
        <Accordions data={faq}>
          <h2>
            Chcesz wiedzieć <strong>więcej?</strong>
          </h2>
        </Accordions>
        <Footer>
          <Footer.Heading>
            Potrzebujesz <strong>więcej informacji?</strong>
          </Footer.Heading>
          <Footer.Paragraph>
            Blogerze! Youtuberze! Influencerze! Czekamy aż do nas napiszesz lub
            zadzwonisz. Jak się do nas nie odezwiesz, to trudno będzie nam Ci pomóc!
          </Footer.Paragraph>
        </Footer>
      </Layout>
    );
  }
}

export default howItWorks;
