import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AccordionItem = styled.li`
  margin-bottom: 12px;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-bottom: 8px;
  }
`;

const Question = styled.h3`
  color: #4f289a;
  font-size: 16px;
  font-weight: 500;
  display: block;
  border-radius: 40px;
  background-color: #f8f8f8;
  padding: 30px 134px 30px 60px;
  position: relative;
  cursor: pointer;

  &.open::after {
    border-width: 0px 7px 9px 7px;
    border-color: transparent transparent #efba35 transparent;

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      border-width: 0px 5.5px 6px 5.5px;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 7px 0 7px;
    border-color: #efba35 transparent transparent transparent;
    top: 50%;
    right: 36px;
    transform: translateY(-50%);

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      border-width: 6px 5.5px 0 6px;
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 16px;
    padding: 20px 78px 20px 39px;
  }
`;

const Answer = styled.p`
  color: #4f289a;
  font-size: 17px;
  font-weight: 400;
  padding: 30px 45px 18px;
  display: none;
  line-height: 32px;

  &.open {
    display: block;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 15px;
    padding: 20px 30px 8px;
  }
`;

const Accordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionItem>
      <Question
        className={`${isOpen ? 'open' : ''} question`}
        onClick={handleClick}
      >
        {question}
      </Question>
      <Answer className={`${isOpen ? 'open' : ''} answer`}>
        {answer}
      </Answer>
    </AccordionItem>
  );
};

Accordion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default Accordion;
